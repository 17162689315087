import felixStart from '@/index';
import i18n from 'i18next';
import localizationResourcesExt from '#/config/localizationResourcesExt';
import '#/assets/styles/baseExt.scss';
import { configureStoreExt } from '#/store/storeExt';

configureStoreExt();
felixStart();

Object.keys(localizationResourcesExt)
    .forEach(lng => i18n.addResourceBundle(lng, 'translation', localizationResourcesExt[lng].translation, true, true));