import React from 'react';
import { connect } from 'react-redux';
import Spoiler from '@/components/Spoiler';
import service from '@/services';
import { FELIX_REST } from '@/config/const'
import { sortBy, isEmpty, flatten } from 'lodash';
import { getBasicNewProjectData } from '@/actions/getBasicNewProjectData';
import AcceptDelete from '@/components/Helper/AcceptDelete';
import { getPpmRequestData } from '#/actions/ppm/getPpmRequestData';
export const getFilesProject = (id) => service.get(`/file/project/${id}`);
export const getFilesPpmRequest = (id) => service.get(`/file/ppmRequest/${id}`);
const getFilesExternal = (id, type) => service.get(`/file/external/${type}/${id}`);
const getIds = (list) => list ? list.map(item => `${item.id}_${item.acceptDelete}`) : [];

export interface IDocListProps {
  dict?: Dict;
  getNewProjectData?: (id: number) => void;
  getPpmRequestData?: (id: number) => void;
  files?: any[];
  projectId?: number;
  ppmRequestId?: number;
  otherId?: number;
  acceptedFileSections?: DictDataItem[];
  skipLoadCurrentFiles?: boolean;
  loadByProject?: boolean;
  loadByPpmRequest?: boolean;
  sectionCode?: string;
  isOnlyProjectFiles?: boolean;
  afterRemove?: () => void;
  defaultSpoilerState?: boolean;
  isRejectRemove?: boolean;
  isHideEmpty?: boolean;
  isAcceptDelete?: boolean;
  hideFileType?: boolean;
  isWithoutSection?: boolean;
  forceLoad?: boolean;
  uniqueByName?: boolean;
}

class DocList extends React.Component<IDocListProps, any> {

  constructor(props) {
    super(props);

    this.state = {
      items: {},
      dictFileType: [],
      dictFileTypeSection: [],
      removedIds: []
    };

    this.getFilesInfo = this.getFilesInfo.bind(this);
  }

  componentDidMount() {
    this.getFilesInfoByOwner();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dict.fileSection.isLoad !== this.props.dict.fileSection.isLoad
      || prevProps.dict.fileType.isLoad !== this.props.dict.fileType.isLoad
      || getIds(prevProps.files).join() !== getIds(this.props.files).join()
      || (prevProps.otherId !== this.props.otherId && !isNaN(this.props.otherId))
      || prevProps.projectId !== this.props.projectId
      || prevProps.ppmRequestId !== this.props.ppmRequestId) {
      this.getFilesInfoByOwner();
    }
  }

  getFileSections() {
    return isEmpty(this.props.acceptedFileSections) ? this.props.dict.fileSection.data : this.props.acceptedFileSections;
  }

  getFilesInfoByOwner() {
    let id;
    if (this.props.loadByProject) {
      id = this.props.projectId;
    }
    if (this.props.loadByPpmRequest) {
      id = this.props.ppmRequestId;
    }

    this.getFilesInfo(id, this.props.otherId);
  }

  async getFilesInfo(id, otherId) {
    if (this.props.skipLoadCurrentFiles) {
      return;
    }

    if (!isEmpty(this.props.dict.fileType.data) && isEmpty(this.state.dictFileType)) {
      this.setState({
        dictFileType: this.props.dict.fileType.data,
      });
    }

    const sections = sortBy(this.getFileSections(), 'sort');

    if (!isEmpty(sections) && isEmpty(this.state.dictFileTypeSection)) {
      this.setState({
        dictFileTypeSection: sections,
      });
    }

    let data = this.props.files || [];

    if (isEmpty(data) || this.props.forceLoad) {
      if (this.props.loadByProject === true && id) {
        data = await getFilesProject(id);
      } else if (this.props.loadByPpmRequest === true && id) {
        data = await getFilesPpmRequest(id);
      } else if (!this.props.loadByProject && !id && otherId) {
        if (otherId > 0) {
          data = await getFilesExternal(otherId, this.props.sectionCode);
        } else {
          data = [];
        }
      }
    }

    if (isEmpty(data)) {
      this.setState({items: []});
      return;
    }

    const allSection = {};
    sections.forEach((item) => {
      allSection[item.id] = [];
    });

    this.setState({
      items: data.reduce((acc, item) => {
        const sectionId = item.typeSectionId;

        if (!acc[sectionId]) {
          acc[sectionId] = [];
        }

        if ((otherId === undefined
          || parseInt(otherId) === parseInt(item.externalId)
          || otherId === null && item.externalId === null)
          && (!item.externalId || !this.props.isOnlyProjectFiles)) {
          acc[sectionId].push(item);
        }

        return acc;
      }, allSection),
    });
  }

  getData = () => this.state.items;

  getTypeFile = (typeId) => this.state.dictFileType.find(fileType => typeId === fileType.id) || {}

  removeFile = (id) => {
    service.remove(`/file/${id}`).then(() => {
      if (this.props.afterRemove) {
        this.props.afterRemove();
      } else if (this.props.projectId) {
        this.props.getNewProjectData(this.props.projectId);
      } else if (this.props.ppmRequestId) {
        this.props.getPpmRequestData(this.props.ppmRequestId);
      }

      this.setState(prev => ({
        ...prev,
        removedIds: [...prev.removedIds, id]
      }));
    });
  }

  isEmpty() {
    return isEmpty(flatten(Object.values(this.state.items)));
  }

  render() {
    const { defaultSpoilerState, isRejectRemove } = this.props;
    const items = this.state.items || {};

    return (
      <React.Fragment>
        {this.state.dictFileTypeSection.map(section => {
          if (this.props.sectionCode && section.code !== this.props.sectionCode) {
            return null;
          }

          const itemsCount = items[section.id]?.length;



          const uniqueByName = (values) => {
            if(!values || !this.props.uniqueByName) {
              return values;
            }
            const keys = {};
            values.forEach(val => {
              if (!keys[val.name]) {
                keys[val.name] = [];
              }
              keys[val.name].push(val);
            });
            const result = [];
            Object.keys(keys).forEach(key => {
              result.push(keys[key][0]);
            });
            return result;
          }

          const itemsList = (
            <React.Fragment key={section.id}>
              {this.props.isHideEmpty || !items[section.id] || items[section.id].length ? null : (
                <div>Нет файлов</div>
              )}
              {items[section.id] && uniqueByName(items[section.id])
                .filter(item => !this.state.removedIds.includes(item?.id)).map(item => {
                const link = item.externalLink || `${FELIX_REST}/file/${item.id}`;
                if (!item.acceptDelete) {
                  item.acceptDelete = this.props.isAcceptDelete;
                }
                return (
                  <div key={item.id} className="docList">
                    {this.props.hideFileType
                      ? <a href={link} target='_blank'>{item.name}</a>
                      : <a href={link} target='_blank'>{this.getTypeFile(item.typeId).name} - {item.name}</a>}

                    {item.acceptDelete && !isRejectRemove && (
                      <AcceptDelete
                        className="input-file-felix__remove"
                        onOk={() => this.removeFile(item.id)}
                        buttonType="text"
                      >x</AcceptDelete>
                    )}
                  </div>
                )
              })}
            </React.Fragment>
          );

          if (this.props.isWithoutSection) {
            return itemsList
          }

          const title = [section.name, (itemsCount ? `(${itemsCount})` : '')].join(' ');

          return (
            <Spoiler title={title} key={section.id} defaultState={defaultSpoilerState}>
              {itemsList}
            </Spoiler>
          )
        })}
      </React.Fragment>
    )
  }
}

const mapStateToProp = (state) => {
  const files = [];
  if (state.NewProject.newProjectData.id) {
    files.push(...state.NewProject.newProjectData.files);
  }
  if (state.PpmRequest.data.id) {
    files.push(...state.PpmRequest.data.files);
  }

  return {
    files,
    dict: state.dict,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getNewProjectData: (id) => dispatch(getBasicNewProjectData(id)),
  getPpmRequestData: (id) => dispatch(getPpmRequestData(id)),
});

export default connect(mapStateToProp, mapDispatchToProps, null, { forwardRef: true })(DocList);
