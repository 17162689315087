import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState, store } from '@/store';
import ReducersExt from '#/reducers';
import { combineReducers, Reducer } from 'redux';
import Reducers from '@/reducers';

export function configureStoreExt(){
  store['injectReducer'](ReducersExt);
}

const stateExtType = combineReducers({...Reducers, ...ReducersExt});
type GetInnerType<S> = S extends Reducer<infer T> ? T : never
export type RootStateExt = RootState & GetInnerType<typeof stateExtType>;
export const useAppSelectorExt: TypedUseSelectorHook<RootStateExt> = useSelector;